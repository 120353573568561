import { useUserApi } from '~/api/user'
import { useAppStore } from '~/store/app'
import { useValidate } from '~/composables/useValidate'
import { MODAL_TYPES } from '~/config/constant'
import { decryptWithAES } from '~/helper'
interface LoginForm {
  username: string;
  password: string;
}
export const useLogin = () => {
  const { LOG_IN } = useUserApi()
  const { $axios, $alertMsg, $bvModal, $device, $isIOS } = useNuxtApp()
  const { $pinia } = useNuxtApp()
  const { setCurrentUser } = useAppStore($pinia)
  const { validateRefs } = useValidate()
  const username = ref<(HTMLElement & { resetValidate: () => boolean }) | null>(null)
  const password = ref<(HTMLElement & { resetValidate: () => boolean }) | null>(null)
  const isLoading = ref<boolean>(false)
  const formData: LoginForm = reactive({
    username: '',
    password: decryptWithAES('')
  })

  const submit = async () => {
    const validateList: string | any[] = reactive([username, password])
    const isValid: boolean = validateRefs(validateList)
    if (isValid && !isLoading.value) {
      await login(formData)
      if ($device.isMobileOrTablet) {
        setTimeout(() => window.scrollTo(0, $isIOS ? -100 : 0), 100)
      }
    }
  }

  const login = async (formData: LoginForm) => {
    try {
      isLoading.value = true
      const res = await ($axios as any).$post(LOG_IN, formData)
      if (res && res.status === 'OK') {
        setCurrentUser(res.data[0])
        $bvModal.hide(MODAL_TYPES.LOGIN)
        username.value?.resetValidate()
        password.value?.resetValidate()
      } else {
        setCurrentUser(null)
        $alertMsg('error', res.message)
      }
      isLoading.value = false
    } catch (error) {
      setCurrentUser(null)
      isLoading.value = false
    }
  }

  return {
    username,
    password,
    formData,
    isLoading,
    submit
  }
}
